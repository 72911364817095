<template>
  <base-modal
    :animation-duration="animationDuration"
    :show="show"
    :show-close="false"
    footer-classes="justify-content-center"
    header-classes="justify-content-center"
  >
    <template #header>
      <div>
        <h4 v-if="error.statusCode === 404">
          Page not found
        </h4>
        <h4 v-else>
          An error occurred
        </h4>
      </div>
    </template>
    <p :style="{ textAlign: 'center' }">
      Back to the <strong><nuxt-link to="/">Home page</nuxt-link></strong>
    </p>
  </base-modal>
</template>

<script>
import { BaseModal } from "~/components/Library/UIComponents";

// you can set a custom layout for the error page
export default {
  name: "ErrorLayout",
  components: {
    [BaseModal.name]: BaseModal,
  },
  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 0
      })
    },
    show: {
      type: Boolean,
      default: true
    },
    animationDuration: {
      type: Number,
      default: 1000,
      description: 'Modal transition duration'
    }
  },
}
</script>
